import * as React from "react"
import { Link } from "gatsby"
import {
  Container
} from '../constants'
import { NotFoundContainer, NotFoundSvg } from "../components/NotFound/NotFoundComponents"
import { GlobalContext } from "../GlobalContext"

const NotFoundPage = () => {
  const { theme , setTheme } = React.useContext(GlobalContext)
  return (
    <Container>
      <NotFoundContainer theme={theme}>
        <h1>Looks like this page doesn't exist</h1>
        <NotFoundSvg />
      </NotFoundContainer>
    </Container>
  )
}

export default NotFoundPage
